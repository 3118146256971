// import { createApp } from 'vue'
//import Projects from "../vue/Projects.vue";
//import Paginate from "vue-paginate";
//import Vue from "vue";
import LazyLoad from "vanilla-lazyload";
import Swiper, { Navigation } from 'swiper';
import SmoothScroll from "smooth-scroll";
import bodymovin from 'lottie-web';


Swiper.use([Navigation]);

// Intiate Vuejs instance if we find #appVue (just in Projects page)
// const appVue = document.getElementById( "appVue" );
// if (appVue) {
//     Vue.use(Paginate)

//     var app = new Vue({
//         el: '#appVue',
//         delimiters: ["${","}"],
//         components: {
//             Projects
//         }
//     });
// }



// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    const lottieTest = document.getElementById('lottieTest');
    const animationTest = bodymovin.loadAnimation({
        container: lottieTest,
        path: '/img/lotties/Joes-Joe-Dont-Poach.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const doinGood = document.getElementById('doinGood');
    const animationDoinGood = bodymovin.loadAnimation({
        container: doinGood,
        path: '/img/lotties/Joes-Lottie-Doing-Good.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const feelinGood = document.getElementById('feelinGood');
    const animationFeelinGood = bodymovin.loadAnimation({
        container: feelinGood,
        path: '/img/lotties/Joes-Lottie-Feeling-Good.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const joeDontPoach = document.getElementById('joeDontPoach');
    const animationJoeDontPoach = bodymovin.loadAnimation({
        container: joeDontPoach,
        path: '/img/lotties/Joes-Joe-Dont-Poach.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const fuelUpRefuel = document.getElementById('fuelUpRefuel');
    const animationFuelUpRefuel = bodymovin.loadAnimation({
        container: fuelUpRefuel,
        path: '/img/lotties/Joes-Fuelup-Refuel.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const shopJoes = document.getElementById('shopJoes');
    const animationShopJoes = bodymovin.loadAnimation({
        container: shopJoes,
        path: '/img/lotties/Joes-Shop-Merch.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const scoreFreeStuff = document.getElementById('scoreFreeStuff');
    const animationScoreFreeStuff = bodymovin.loadAnimation({
        container: scoreFreeStuff,
        path: '/img/lotties/Joes-Score-Free-Stuff.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const aboutJoes = document.getElementById('aboutJoes');
    const animationAboutJoes = bodymovin.loadAnimation({
        container: aboutJoes,
        path: '/img/lotties/Joes-About-Joes.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    //Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });


    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy-video")),

    lazyVideoLoad = function(){
        if (screen.width > 768 && "IntersectionObserver" in window) {
            var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(video) {
                    if (video.isIntersecting) {
                        for (var source in video.target.children) {
                            var videoSource = video.target.children[source];
                            if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                                videoSource.src = videoSource.dataset.src;
                            }
                        }
                        video.target.load();
                        video.target.classList.remove("lazy-video");
                        lazyVideoObserver.unobserve(video.target);
                    }
                });
            });
            lazyVideos.forEach(function(lazyVideo) {
                lazyVideoObserver.observe(lazyVideo);
            });
        }
    };

    window.addEventListener('resize', lazyVideoLoad());
    
    // Trigger home video
        lazyVideoLoad();
   
    
    //Smoothscroll
    var scroll = new SmoothScroll('a[href*="#"]', {
        offset: '50',
    });

    const links = document.querySelectorAll('.menu-list-block__ul > li a');
    const sections = document.querySelectorAll('.menu-list-block__wrapper .menu-list-block__category');
    const menuList = document.querySelector('.menu-list-block');

    if(menuList){ 
        function changeLinkState() {
          let index = sections.length;

            while(--index && window.scrollY - 400 < sections[index].offsetTop) {}

           links.forEach(link => {
                link.classList.remove("active");
                link.classList.remove("hover");
            });
                     
          links[index].classList.add('active');

          console.log(index);

        }

        changeLinkState();
        window.addEventListener('scroll', changeLinkState);


        links.forEach(function(menuItem) {
            menuItem.addEventListener("mouseenter", function() {
            menuItem.classList.add("hover");

          })
            menuItem.addEventListener("mouseout", function() {
            menuItem.classList.remove("hover");
          })
        })

    }


    //Toggle mobile menu
    const navTrigger = document.querySelector('.js-open-nav');
    const mobNav = document.querySelector('.mobile-nav__container');
    const projectWrapper = document.getElementById('app');

    if(navTrigger){ 
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('is-active');
            mobNav.classList.toggle('js-is-open');
            projectWrapper.classList.toggle('js-is-open');
        }, false);
    }

    var swiper = new Swiper('.circle-swiper', {

        grabCursor: 'false',
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: { 
            1280: { 
                 spaceBetween: 320,
            }
        },
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    var swiper = new Swiper('.square-swiper', {

        grabCursor: 'false',
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 32,

        breakpoints: { 
            1024: {
                spaceBetween: 70, 
            },
        },
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {

        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})